import React, { useContext } from 'react';
import {  Card, CardHeader, Button } from 'reactstrap'
import AppContext from '../AppContext'
import bata from '../images/bata.png';
import botas from '../images/botas.png';
import careta from '../images/careta.png';
import gorro from '../images/gorro.png';
import guantes from '../images/guantes.png';
import hm from '../images/hm.png';
import medica from '../images/medica.png';
import n95 from '../images/n95.png';
import distancia from '../images/distancia.png'
import YoutubeIcon from 'mdi-react/YoutubeIcon'

function ProtectionView(props) {
    const { state, send } = useContext(AppContext);
    const { profession, activity } = state.context;
  
    const protection = [
      {
        NAME: 'Sana Distancia',
        ICON: distancia,
        VIDEO_URL: null
      },
      {
        NAME: 'Higiene de Manos',
        ICON: hm,
        VIDEO_URL: 'v3Gvgr4tM34'
      },
      {
        NAME: 'Bata Impermeable',
        ICON: bata,
        VIDEO_URL: 'w9Y-kSLHKxs'
      },
      {
        NAME: 'Bata Esteril',
        ICON: bata,
        VIDEO_URL: 'w9Y-kSLHKxs'
      },
      {
        NAME: 'Guantes',
        ICON: guantes,
        VIDEO_URL: 'NQByohmDTI4'
      },
      {
        NAME: 'Googles/Careta',
        ICON: careta,
        VIDEO_URL: 'h4wLMqOG-cY'
      },
      {
        NAME: { N95: 'Mascarilla  N95', Tricapa: 'Mascarilla Tricapa' },
        ICON: { N95: n95, Tricapa: medica },
        VIDEO_URL: { N95: 'fK2qsrLFwaE', Tricapa: 'KP0K8Ds2zKY' }
      },
      {
        NAME: 'Botas',
        ICON: botas,
        VIDEO_URL: null
      },
      {
        NAME: 'Gorro',
        ICON: gorro,
        VIDEO_URL: null
      }, {
        NAME: 'Caja de Acrílico',
        ICON: null,
        VIDEO_URL: null
      }, {
        NAME: { N95: 'Mascarilla para Paciente N95', Tricapa: 'Mascarilla para Paciente Tricapa' },
        ICON: { N95: n95, Tricapa: medica },
        VIDEO_URL: { N95: 'fK2qsrLFwaE', Tricapa: 'KP0K8Ds2zKY' }
      }, {
        NAME: 'Cuna Radiante',
        ICON: null,
        VIDEO_URL: null
      }, {
        NAME: 'Notas',
        ICON: null,
        VIDEO_URL: null
      }
    ]
  
    return (
      <div>
        <div style={{ fontWeight: '400', height: '30px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          {profession.NOMBRE}
        </div>
        <div style={{ fontWeight: '400', height: '30px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          {activity[0]}
        </div>
        <p className="text-center m-3" style={{ fontSize: 12 }}>Para esta actividad usted requiere:</p>
        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {protection.map((element, index) => {
            if (activity[index + 1])
              if (index !== 6 && index !== 10 && index !== 12) {
                return (
                  <Card key={index} className="mb-2" style={{ backgroundColor: '#DDD' }}>
                    <CardHeader style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                      <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '60px', width: '60px', borderRadius: '50%', marginRight: '10px' }}>
                          <img src={element.ICON} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '5px' }} />
                        </div>
                        {element.NAME}
                      </div>
                      {element.VIDEO_URL &&
                        <Button color="default" style={{ color: '#c4302b', flexShrink: 0 }}
                          onClick={() => send({ type: 'OPEN_MODAL_BTN', data: element.VIDEO_URL })}>
                          <YoutubeIcon className="icon" />
                        </Button>}
                    </CardHeader>
                  </Card>
                )
              } else
                if (index == 6) {
                  if (element.NAME[activity[7]])
                    return (
                      <Card key={index} className="mb-2" style={{ backgroundColor: '#DDD' }}>
                        <CardHeader style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                          <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                            <div style={{ height: '60px', width: '60px', borderRadius: '50%', marginRight: '10px' }}>
                              <img src={element.ICON[activity[7]]} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '5px' }} />
                            </div>
                            {element.NAME[activity[7]]}
                          </div>
                          {element.VIDEO_URL[activity[7]] &&
                            <Button color="default" style={{ color: '#c4302b', flexShrink: 0 }}
                              onClick={() => send({ type: 'OPEN_MODAL_BTN', data: element.VIDEO_URL[activity[7]] })}>
                              <YoutubeIcon className="icon" />
                            </Button>}
                        </CardHeader>
                      </Card>
                    )
                } else
                  if (index == 10) {
                    if (element.NAME[activity[11]])
                      return (
                        <Card key={index} className="mb-2" style={{ backgroundColor: '#DDD' }}>
                          <CardHeader style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                            <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                              <div style={{ height: '60px', width: '60px', borderRadius: '50%', marginRight: '10px' }}>
                                <img src={element.ICON[activity[11]]} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '5px' }} />
                              </div>
                              {element.NAME[activity[11]]}
                            </div>
                            {element.VIDEO_URL[activity[11]] &&
                              <Button color="default" style={{ color: '#c4302b', flexShrink: 0 }}
                                onClick={() => send({ type: 'OPEN_MODAL_BTN', data: element.VIDEO_URL[activity[11]] })}>
                                <YoutubeIcon className="icon" />
                              </Button>}
                          </CardHeader>
                        </Card>
                      )
                  }
                  else
                    if (index == 12) {
                      return (
                        <Card key={index} className="mb-2" style={{ backgroundColor: '#DDD' }}>
                          <CardHeader style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                            <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>{element.NAME}</div>
                                <div>{activity[13]}</div>
                              </div>
                            </div>
                            {element.VIDEO_URL &&
                              <Button color="default" style={{ color: '#c4302b', flexShrink: 0 }}
                                onClick={() => send({ type: 'OPEN_MODAL_BTN', data: element.VIDEO_URL })}>
                                <YoutubeIcon className="icon" />
                              </Button>}
                          </CardHeader>
                        </Card>
                      )
                    }
          })}
        </div>
      </div>
    )
  }

export default ProtectionView
