import React from 'react'
import YouTube from 'react-youtube';
import {  Modal } from 'reactstrap'
function ViewingModal(props) {
    return (
        <Modal {...props}>
            <div className="m-1">
                <YouTube
                    videoId={props.video}
                    opts={
                        {
                            width: '100%',
                            playerVars: {
                                autoplay: '1',
                                modestbranding: '1',
                                showinfo: '0',
                                rel: '0'
                            },
                        }
                    }
                />
            </div>
        </Modal>
    )
}

export default ViewingModal
