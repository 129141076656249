import React, { useContext } from 'react';
import {  Button } from 'reactstrap'
import AppContext from '../AppContext'
import himfg from '../images/himfg.png'
import unamlab from '../images/unamlab.png'
import logofinal from '../images/logofinal.png'
import aidy_mail_header from '../images/aidy_mail_header.png'

function WelcomeView(props) {
    const { state, send } = useContext(AppContext);
    return (
      <div className="text-center" style={{ backgroundColor: '#17a2b8', height: '100vh', flexDirection: 'column', margin: 0, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1.9, flexDirection: 'column', margin: 0, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '130px', width: '130px', borderRadius: '50%', marginRight: '10px', alignSelf: 'center' }}>
            <img src={logofinal} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '5px' }} />
          </div>
          <div className="px-3">
            <p style={{ color: 'white', fontSize: 18, fontWeight: '500' }} className="mb-0">Guia de Equipo de Protección Personal para COVID-19</p>
            <p style={{ color: 'white', fontSize: 13 }}>Basada en medidas de los organos oficiales</p>
          </div>
          <div className="m-3">
            <p className="mb-1" style={{ color: 'white', fontSize: 10 }}>Una colaboración de:</p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              <a href="https://aidytech.com" target="_blank"><img className="m-1" src={aidy_mail_header} style={{ height: "45px" }} /></a>
              <img className="m-1" src={himfg} style={{ height: "60px" }} />
              <img className="m-1" src={unamlab} style={{ height: "45px" }} />
            </div>
          </div>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button className="my-3" style={{ width: '100px' }} onClick={() => send('START')}>Iniciar</Button>
        </div>
        <div className="mb-4" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <p className="mb-1" style={{ color: 'white', fontSize: 10 }}>Avalada por:</p>
          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', justifyItems: 'flex-start' }}>
            <img className="m-1" src={himfg} style={{ objectFit: 'contain', width: "50px" }} />
          </div>
        </div>
      </div >
    )
  }

export default WelcomeView
