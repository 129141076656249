import React, { useContext } from 'react';
import { Button, Modal, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import AppContext from '../AppContext'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'

function ActivityView(props) {
    const { state, send } = useContext(AppContext);
    const { profession, activity_search, diagnosis } = state.context;
    return (
      <div>
        <div style={{ fontWeight: '400', height: '30px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          {profession.NOMBRE}
        </div>
        <p className="text-center mt-3" style={{ fontSize: 12 }}>Seleccione una Actividad</p>
        <div className="mt-2 mb-3 mx-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><MagnifyIcon /></InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Buscar Actividad" type="text" value={activity_search || ""} onChange={(event) => send('SEARCH', { value: event.target.value })} />
            {activity_search ?
              <InputGroupAddon addonType="append">
                <Button onClick={() => send('CLEAN')} color="default"><CloseCircleIcon style={{ color: 'gray' }} /></Button>
              </InputGroupAddon> : null
            }
          </InputGroup>
        </div>
  
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {profession.DATA.filter((activity) => activity_search ? activity[0].includes(activity_search) : true).map((activity, index) => (
            <Button color="secondary" key={index} block className="mt-2" style={{
              height: '70px', lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
            }}
              onClick={() => send({ type: 'ACTIVITY_SELECTED', data: activity })}>
              <div className="text-left" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                {activity[0]}
              </div>
              <ChevronRightIcon style={{ flexShrink: 0, color: '#17a2b8' }} className="icon" />
            </Button>
          ))}
        </div>
      </div>
    )
  }

export default ActivityView
