
const data = //ENFERMERIA"],
[{
  ID_GRUPO: 0,
  ID_PROFESION: 1,
  NOMBRE: 'Enfermería',
  DATA: [
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Dar de comer o alimentar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Cambiar el pañal o auxiliar en el aseo", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Administración de medicamentos ", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Baño del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambio de sábanas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de material de sala", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Dispensar/Retiro de charola de comida", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambiar TV", "X", 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambio de pañal", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Realizar cambio de pañal INMEDIATO después de la evacuación. Poner el pañal en una bolsa de plástico cerrada y desechar"],
    ["Medición de gasto fecal/gasto urinario", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, "Tricapa", 0, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Hemodiálisis o hemodiafiltración", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0]

  ]
}
  , {
  ID_GRUPO: 2,
  ID_PROFESION: 2,
  NOMBRE: 'Enfermería Quirúrgica',
  DATA: [
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Administar medicamento intravenoso en paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Limpieza de paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambiar el pañal o auxiliar en el aseo", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, 0, 0, 0],
    ["Asepsia y antisepsia de piel", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Paciente intubado utilizar N95"],
    ["Traslado de paciente desde el quirófano", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales paciente no intubado", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Paciente intubado utilizar N95"],
    ["Entrada y salida de la sala de quirófano ", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, "Evitarlo en todo lo posible"],
    ["Cambio de sábanas posterior a paciente", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Acomodo de mesa quirúrgica previo al paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de material de sala", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Dispensar/Retiro de charola de comida", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambio de pañal paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, "Realizar cambio de pañal INMEDIATO después de la evacuación. Poner el pañal en una bolsa de plástico cerrada y desechar"],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, 0],
  ]
}
  , {
  ID_GRUPO: 1,
  ID_PROFESION: 3,
  NOMBRE: 'Médico Pediatra o Neonatologo',
  DATA: [
    //MEDICO PEDIATRA O NEONATOLOGO"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, 0],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Reanimación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, 0],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Dar de comer o alimentar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Auxiliar en el aseo", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Acomodo de cuna/incubadora", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 1, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación de drenaje pleural", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Punción ventricular", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 4,
  NOMBRE: 'Médicos',
  DATA: [
    //MEDICOS"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo (Evítelo)", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Dar de comer o alimentar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Auxiliar en el aseo", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Dispensar/Retiro de charola de comida", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambiar TV", "X", 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, "Tricapa", 0, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Hemodiálisis o hemodiafiltración", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Si el paciente se intuba o seda, es necesario el uso del N95 por el personal "],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Colocación de drenaje pleural", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Punción ventricular", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, 0],
    ["Fondo de ojo", 0, 1, 1, 0, 1, 1, "N95", 0, 0, 0, "Tricapa", 0, "Por la producción de aerosoles tan cercano, se recomienda el uso de N95 Safadi K, et al. BMJ Open Ophth 2020;5:e000487. doi:10.1136/bmjophth-2020-000487"],
    ["Aspirado de médula ósea", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Si el paciente se intuba o seda, es necesario el uso del N95 por el personal "],
    ["Realización de ecocardiograma o ultrasonido", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Se reomienda cubrir el equipo con una capa de plástico para disminuir riesgo de contaminación por gotas, y después de su uso, desinfectar el equipo con alcohol al 70%"],

  ]
}
  , {
  ID_GRUPO: 1,
  ID_PROFESION: 5,
  NOMBRE: 'Médico o Neonatologo en UCIN',
  DATA: [
    //NEONATOLOGO O MEDICO EN UCIN"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, 0],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 1, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 1, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Dar de comer o alimentar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Auxiliar en el aseo", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Acomodo de cuna/incubadora", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 1, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Colocación de drenaje pleural", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
    ["Punción ventricular", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 1, 0],
  ]
}, {
  ID_GRUPO: 0,
  ID_PROFESION: 6,
  NOMBRE: 'Anestesiología',
  DATA: [
    //ANESTESIOLOGIA"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "N95", 1, 1, 0, "Tricapa", 0, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Administración anestesia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Sedación fuera de quirófano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Si el paciente requiere presión positiva con bolsa válvula mascarilla se debe utilizar N95"],

  ]
}, {
  ID_GRUPO: 1,
  ID_PROFESION: 6,
  NOMBRE: 'Anestesiología',
  DATA: [
    //ANESTESIOLOGIA"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "N95", 1, 1, 0, "Tricapa", 0, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Administración anestesia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Sedación fuera de quirófano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Si el paciente requiere presión positiva con bolsa válvula mascarilla se debe utilizar N95"],

  ]
}, {
  ID_GRUPO: 2,
  ID_PROFESION: 6,
  NOMBRE: 'Anestesiología',
  DATA: [
    //ANESTESIOLOGIA"],
    ["Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Toma de muestra de orina", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "N95", 1, 1, 0, "Tricapa", 0, "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Administración anestesia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Punción lumbar", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa", 0, 0],
    ["Sedación fuera de quirófano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa", 0, "Si el paciente requiere presión positiva con bolsa válvula mascarilla se debe utilizar N95"],

  ]
}
  , {
  ID_GRUPO: 2,
  ID_PROFESION: 7,
  NOMBRE: 'Cirujanos',
  DATA: [
    //CIRUJANO"],
    ["Intubación / Extubación", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 1, 0, 0, "En intubación se sugiere el uso de videolaringoscopio. *Consenso HIMFG"],
    ["Aspiración de secreciones por tráquea", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Dar resucitación cardiopulmonar", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Desfibrilación", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación de alto flujo", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 1, "Tricapa", 0, "Evítelo si es posible"],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 1, 0, 0, 0],
    ["Inducción de esputo", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Colocacion de SNG o SOG", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 1, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Broncoscopía", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Endoscopía esofágica alta", 0, 1, 1, 1, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Exploración física", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Auxiliar en el aseo", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Traslado de paciente", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Toma de signos vitales", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Acomodo de cama", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Diálisis peritoneal", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Toma de muestra de orina", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Cambio de posición de paciente", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, "No se recomienda introducir expediente al quirófano"],
    ["Toma de muestra sanguínea", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Cirugía", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Punción lumbar", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Colocación y retiro de catéteres", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Colocación de drenaje pleural", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
    ["Punción ventricular", 0, 1, 1, 1, 1, 1, "N95", 1, 0, 0, "Tricapa", 0, 0],
  ]
}
  , {
  ID_GRUPO: 1,
  ID_PROFESION: 8,
  NOMBRE: 'Enfermería en Labor o Parto',
  DATA: [
    //ENFERMERA EN LABOR O PARTO"],
    ["Recepción/limpieza del recien nacido", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, 0, 0],
    ["Ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Doble pares de guantes"],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Colocacion de SNG o SOG", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo si es posible"],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Realice solo si es absolutamente necesario"],
    ["BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Evítelo"],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, "Utilice inhaladores de dosis medida. Realice solo si es absolutamente necesario"],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, "Tricapa", 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Aplicación de medicamentos ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Toma de signos vitales", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Cambio de sábanas", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Acomodo de cuna radiante o incubadora", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, "Tricapa", 0],
    ["Toma de material de sala", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Colocación de vía intravenosa", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Cambio de pañal del recién nacido", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, "Tricapa", 0],
    ["Medición de gasto fecal/gasto urinario", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Cambio de posición de paciente", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Revisión de catéteres, sondas", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "N95", 1, 1, 0, 0, "Tricapa", "No se recomienda introducir expediente o cárdex al área/habitación del paciente"],
    ["Toma de muestra sanguínea", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Colocación de toalla sanitaria de la mamá", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Amniorrexis", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Monitoreo fetal", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, "Tricapa", 0],
    ["Acomodo de camilla o mesa de expulsión", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, "Tricapa", 0],
    ["Alojamiento conjunto (puerperio de bajo riesgo)", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 1, 0, 1, "Tricapa", 0],
    ["Toma de tamiz neonatal ", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 1, "Tricapa", 0],
  ]
}//FAMILIARES"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 9,
  NOMBRE: 'Familiares',
  DATA: [
    ["Intubación / Extubación o cualquier procedimiento que genere secreciones", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "El familiar debe retirarse, si mantiene en tal sitio deberá utilizar el mismo EPP que médico y enfermera", 0, "El familiar debe retirarse, si se queda deberá utilizar el mismo EPP que médico y enfermera"],
    ["Algunos tipos de ventilaciones que generen aerosoles (gotitas microscópicas)", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, "Tricapa encima", 0, 0],
    ["Presencia o auxilio del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa si lo tolera", 0, 0],
    ["Abrazar, cargar o mover de lugar al paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa si lo tolera", 0, 0],
    ["Dar de comer o alimentar", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Requiere cambio de guantes previo a esta actividad con higiene de manos"],
    ["Amamantar al paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "La lactancia materna es preferible que continúe durante la infección de la madre o del hijo, salvo condiciones graves de alguno de los dos."],
    ["Cambio de pañal o manejo de excretas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa si lo tolera", 0, "Realizar cambio de pañal INMEDIATO después de la evacuación. Poner el pañal en una bolsa de plástico cerrada y desechar"],
    ["Curación de heridas o estomas", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, "Tricapa si lo tolera", 0, "Requiere cambio de guantes previo a esta actividad, con higiene de manos antes y después "],
    ["Administración de medicamentos vía oral", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Requiere cambio de guantes previo a esta actividad, con higiene de manos antes y después "],

  ]
},
  //PSICOLOGIA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 10,
  NOMBRE: 'Psicología',
  DATA: [
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Atención de paciente", 1, 1, 0, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista fuera de la habitación del paciente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Informes", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  //TRABAJADORES SOCIALES"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 11,
  NOMBRE: 'Trabajadores Sociales',
  DATA: [
    ["Atención de paciente", 1, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista fuera de la habitación del paciente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Informes", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 12,
  NOMBRE: 'Inhaloterapistas',
  DATA: [
    //INHALOTERAPISTAS"],
    ["Auxiliar en Intubación / Extubación", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Auxiliar en la colocación de Ventilación de alto flujo", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Auxiliar en ventilación mecánica no invasiva", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Colocación de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Retiro de mascarilla laríngea", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Auxialiar en ventilación manual con presión positiva; Oscilador de alta frecuencia", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Aspiración abierta profunda de orofaringe o nasofaringe", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Auxiliar en colocación de BIPAP o CPAP", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Tratamientos nebulizados", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista dentro de la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Traslado de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Pase de visita ingresando a la habitación del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodo de cama", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 13,
  NOMBRE: 'Personal de Laboratorio',
  DATA: [
    //PERSONAL DE LABORATORIO"],
    ["Toma de muestra y procesamiento de SARS CoV-2", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Inducción de esputo", 0, 1, 1, 0, 1, 1, "N95", 1, 1, 0, 0, 0, 0],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Atención del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 14,
  NOMBRE: 'Personal de Patología',
  DATA: [
    //PERSONAL DE PATOLOGIA"],
    ["Toma de muestras", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Disección de tejidos", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Realizar autopsia", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "*Evitar si es factible"],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 15,
  NOMBRE: 'Técnico de Rayos X',
  DATA: [
    //TECNICO DE RAYOS X"],
    ["Toma de placas de rayos X", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano con paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Atención del paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Registro de datos en expediente o cárdex", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 16,
  NOMBRE: 'RPBI',
  DATA: [
    //RPBI"],
    ["Limpieza", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Desinfección", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodar/ordenar", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 17,
  NOMBRE: 'Intendencia',
  DATA: [
    //INTENDENCIA"],
    ["Limpieza", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Desinfección", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodar/ordenar", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Limpieza en paciente en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Desinfección  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Contacto cercano  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Acomodar/ordenar  en ventilación de alto flujo o quirófano", 1, 0, 0, 0, 0, 0, "N95", 0, 0, 0, 0, 0, "Solo entrar lo indispensable"],

  ]
}, {
  ID_GRUPO: 1,
  ID_PROFESION: 17,
  NOMBRE: 'Intendencia',
  DATA: [
    //INTENDENCIA"],
    ["Limpieza", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Desinfección", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodar/ordenar", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Limpieza en paciente en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Desinfección  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Contacto cercano  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Acomodar/ordenar  en ventilación de alto flujo o quirófano", 1, 0, 0, 0, 0, 0, "N95", 0, 0, 0, 0, 0, "Solo entrar lo indispensable"],

  ]
}, {
  ID_GRUPO: 2,
  ID_PROFESION: 17,
  NOMBRE: 'Intendencia',
  DATA: [
    //INTENDENCIA"],
    ["Limpieza", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Desinfección", 0, 1, 1, 0, 1, 1, "Tricapa", 1, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodar/ordenar", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Limpieza en paciente en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Desinfección  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Contacto cercano  en ventilación de alto flujo o quirófano", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, "Solo entrar lo indispensable"],
    ["Acomodar/ordenar  en ventilación de alto flujo o quirófano", 1, 0, 0, 0, 0, 0, "N95", 0, 0, 0, 0, 0, "Solo entrar lo indispensable"],

  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 18,
  NOMBRE: 'Camilleros',
  DATA: [
    //CAMILLERO"],
    ["Movilización del paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Movilización del paciente estable", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodo de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Traslado", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}, {
  ID_GRUPO: 1,
  ID_PROFESION: 18,
  NOMBRE: 'Camilleros',
  DATA: [
    //CAMILLERO"],
    ["Movilización del paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Movilización del paciente estable", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodo de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Traslado", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}, {
  ID_GRUPO: 2,
  ID_PROFESION: 18,
  NOMBRE: 'Camilleros',
  DATA: [
    //CAMILLERO"],
    ["Movilización del paciente intubado", 0, 1, 1, 0, 1, 1, "N95", 1, 0, 0, 0, 0, 0],
    ["Movilización del paciente estable", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Acomodo de paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Traslado", 1, 1, 0, 0, 0, 0, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 19,
  NOMBRE: 'Rehabilitación',
  DATA: [
    //REHABILITACION"],
    ["Observación del paciente", 1, 1, 1, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Exploración física", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Fisioterapia asistida", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista dentro de la habitación", 1, 1, 1, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Plan de fisioterapia (verbal, papel)", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Elaboración de notas médicas", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Revisión del expediente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}//ESTAFETA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 20,
  NOMBRE: 'Estafeta',
  DATA: [
    ["Entrega de documentos", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrega de material", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Firma de documentos", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Atención de paciente", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  //RELACIONES PUBLICAS"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 21,
  NOMBRE: 'Relaciones Públicas',
  DATA: [
    ["Atención de pacientes", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  //ADMINISTRATIVO EN AREA CLINICA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 22,
  NOMBRE: 'Aministrativo en Área Clínica',
  DATA: [
    ["Atención de pacientes", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Archivar", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  //COCINA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 23,
  NOMBRE: 'Cocina',
  DATA: [
    ["Atención de pacientes", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Entrevista", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Cocinar", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
},
  //ADMINISTRATIVO EN AREA CENTRAL"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 24,
  NOMBRE: 'Administrativo en Área Central',
  DATA: [
    ["Atención de personal sanitario", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Entrevista", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano con personal sanitario", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Actividades administrativas", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
  ]
},
  //PERSONAL DE ARCHIVO Y BIOESTADISTICA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 25,
  NOMBRE: 'Personal de Archivo y Bioestadística',
  DATA: [
    ["Entrega de expedientes", 1, 1, 0, 0, 0, 0, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Recabar expedientes", 1, 1, 0, 0, 0, 0, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Archivar expedientes", 1, 1, 0, 0, 0, 0, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Atención solicitud de expedientes", 1, 1, 0, 0, 0, 0, "Doble capa", 0, 0, 0, 0, 0, 0],
  ]
},
  //LAVANDERIA"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 26,
  NOMBRE: 'Lavandería',
  DATA: [
    ["Recepción de ropa sucia", 0, 1, 1, 0, 1, 1, "Triple capa", 0, 0, 0, 0, 0, 0],
    ["Lavado de ropa", 0, 1, 1, 0, 1, 1, "Triple capa", 0, 0, 0, 0, 0, 0],
    ["Doblar ropa limpia", 0, 1, 0, 0, 0, 1, "Triple capa", 0, 0, 0, 0, 0, 0],
    ["Acomodar ropa limpia", 0, 1, 0, 0, 0, 1, "Triple capa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 27,
  NOMBRE: 'Nutrición',
  DATA: [
    //NUTRICION"],
    ["Revisión de expediente", 1, 0, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Pesar/Medir/Medición de pliegues", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Pase de visita", 1, 1, 0, 0, 0, 1, "tricapa", 0, 0, 0, 0, 0, 0],
    ["Elaboración de nota médica", 1, 1, 0, 0, 0, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 28,
  NOMBRE: 'Ambulancia',
  DATA: [
    //AMBULANCIA"],
    ["Chofer ", 0, 1, 0, 0, 0, 1, "doble ", 0, 0, 0, 0, 0, 0],
    ["Chofer apoya en movilizar a paciente", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Camillero", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
  ]
}
  //JARDINERO"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 29,
  NOMBRE: 'Jardinero',
  DATA: [
    ["Cortar césped", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Plantar", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Jardinería", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
  ]
},
  //BIBLIOTECARIO"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 30,
  NOMBRE: 'Bibliotecario',
  DATA: [
    ["Entregar libros", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Realizar copias", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Recibir libros", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Atención usuarios", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
  ]
},
  //PERSONAL DE MANTENIMIENTO"]]}
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 31,
  NOMBRE: 'Personal de Mantenimiento',
  DATA: [
    ["Arreglos dentro de habitación", 1, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Arreglos fuera de habitación", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],

  ]
}, {
  ID_GRUPO: 1,
  ID_PROFESION: 31,
  NOMBRE: 'Personal de Mantenimiento',
  DATA: [
    ["Arreglos dentro de habitación", 1, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Arreglos fuera de habitación", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],

  ]
}, {
  ID_GRUPO: 2,
  ID_PROFESION: 31,
  NOMBRE: 'Personal de Mantenimiento',
  DATA: [
    ["Arreglos dentro de habitación", 1, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Arreglos fuera de habitación", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, "Ingresar a la habitación o quirófano cuando el paciente ya haya salido"],

  ]
},
  //PERSONAL DE FUMIGACIÓN
  , {
  ID_GRUPO: 0,
  ID_PROFESION: 32,
  NOMBRE: 'Personal de Fumigación',
  DATA: [
    ["Fumigación en habitación", 1, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0],
    ["Fumigación fuera de habitación", 1, 1, 0, 0, 0, 1, "Doble capa", 0, 0, 0, 0, 0, 0],
    ["Contacto cercano", 0, 1, 1, 0, 1, 1, "Tricapa", 0, 0, 0, 0, 0, 0]
  ]
}];

export default data;