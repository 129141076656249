import logofinal from './images/logofinal.png'
import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import { ButtonGroup, Button } from 'reactstrap'
import HomeIcon from 'mdi-react/HomeIcon'
import './App.css';
import { CSSTransition } from 'react-transition-group'
import ReactGA from 'react-ga';
import data from './data'
import AppContext from './AppContext'
import WelcomeView from './views/WelcomeView'
import DiagnosisView from './views/DiagnosisView'
import ProfessionView from './views/ProfessionView'
import ActivityView from './views/ActivityView'
import ProtectionView from './views/ProtectionView'
import ViewingModal from './views/ViewingModal'
import SubscribeModal from './views/SubscribeModal'
ReactGA.initialize('UA-168691570-1');
ReactGA.pageview('/homepage');

const masterMachine = Machine({
  initial: 'welcome',
  states: {
    welcome: {
      on: {
        START: { target: 'diagnosis', actions: assign({ transitionHomeDirection: "card-view-vertical" }) }
      }
    },
    diagnosis: {
      entry: [
        assign({ profession_search: null })
      ],
      on: {
        DIAGNOSIS_SELECTED: {
          target: 'profession', actions: [assign({ diagnosis: (context, event) => event.data }), assign({ transitionDirection: "card-view" })]
        }
      }
    },
    profession: {
      entry: [
        assign({ activity_search: null })
      ],
      on: {
        CLEAN: {
          actions: assign({ profession_search: null })
        },
        SEARCH: {
          actions: assign({ profession_search: (context, event) => event.value })
        },
        PROFESSION_SELECTED: {
          target: 'activity',
          actions: [assign({ profession: (context, event) => event.data }), assign({ transitionDirection: "card-view" })],
        }
      }
    },
    activity: {
      on: {
        CLEAN: {
          actions: assign({ activity_search: null })
        },
        SEARCH: {
          actions: assign({ activity_search: (context, event) => event.value })
        },
        ACTIVITY_SELECTED: {
          target: 'protection',
          actions: [
            assign({ activity: (context, event) => event.data }), assign({ transitionDirection: "card-view" }),
            (context, event) => {
              //console.log(context, event)
              ReactGA.event({
                category: `${context.profession.NOMBRE}`,
                action: `${context.activity[0]}`,
              });
            }
          ],
        }
      }
    },
    protection: {
      initial: 'normal',
      states: {
        normal: {
          on: {
            OPEN_MODAL_BTN: { target: 'viewing', actions: assign({ currentVideo: (contex, event) => event.data }) }
          }
        },
        viewing: {
          on: {
            CANCEL: 'normal'
          }
        }
      }
    }
  },
  on: {
    HOME_BTN: { target: 'welcome', actions: assign({ transitionHomeDirection: "card-view-vertical-back", transitionDirection: "" }) },
    PROFESSION_BTN: { target: 'profession', actions: assign({ transitionDirection: "card-view-back" }) },
    ACTIVITY_BTN: { target: 'activity', actions: assign({ transitionDirection: "card-view-back" }) },
    DIAGNOSIS_BTN: { target: 'diagnosis', actions: assign({ transitionDirection: "card-view-back" }) }
  }
})


const mailMachine = Machine({
  initial: 'closed',
  states: {
    closed: {
      on:{TIMER: 'open'}
    },
    open: {
      on:{CLOSE: 'dismissed'}
    },
    dismissed: {}
  }
})

function App() {
  const [state, send] = useMachine(masterMachine, { context: { professions: data, transitionDirection: "card-view", transitionHomeDirection: "card-view-vertical" } })
  const [stateMail, sendMail] = useMachine(mailMachine)

  useEffect(() => {
    const timer = setTimeout(() => {
      sendMail('TIMER')
    }, 45000);
    return () => clearTimeout(timer);
  }, []);

  const { transitionDirection, transitionHomeDirection, currentVideo } = state.context;
  return (
    <AppContext.Provider value={{ state, send }}>
      <CSSTransition
        in={!state.matches('welcome')}
        classNames={transitionHomeDirection}
        timeout={300}
        unmountOnExit
      >
        <div className="background" style={{ padding: '0px' }}>
          <div style={{ color: 'white', backgroundColor: '#17a2b8', height: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <div className="pl-3" style={{ flex: 1 }}><Button color="default" style={{ color: 'white', fill: 'white' }} onClick={() => send('HOME_BTN')}><HomeIcon /></Button></div>
            <div className="text-center" style={{ flex: 1 }}>
              <img src={logofinal} style={{ height: '60px', objectFit: 'contain', padding: '5px' }} />
            </div>
            <div style={{ flex: 1 }}>
            </div>
          </div>
          <ButtonGroup className="mt-2 mb-3 mx-3" style={{ display: 'flex' }}>
            <Button color="info" style={{ flex: 1, fontSize: 12 }} active={state.matches('diagnosis')} onClick={() => send('DIAGNOSIS_BTN')}>1.Tipo</Button>
            <Button color="info" style={{ flex: 1, fontSize: 12 }} active={state.matches('profession')} disabled={state.matches('diagnosis')} onClick={() => send('PROFESSION_BTN')}>2.Profesión</Button>
            <Button color="info" style={{ flex: 1, fontSize: 12 }} active={state.matches('activity')} disabled={state.matches('diagnosis') || state.matches('profession')} onClick={() => send('ACTIVITY_BTN')}>3.Actividad</Button>
            <Button color="info" style={{ flex: 1, fontSize: 12 }} active={state.matches('protection')} disabled={state.matches('diagnosis') || state.matches('profession') || state.matches('activity')}>4.EPP</Button>
          </ButtonGroup>
          <CSSTransition
            in={state.matches('diagnosis')}
            classNames={transitionDirection}
            timeout={300}
            unmountOnExit
          >
            <div className="background">
              <DiagnosisView />
            </div>
          </CSSTransition>
          <CSSTransition
            in={state.matches('profession')}
            classNames={transitionDirection}
            timeout={300}
            unmountOnExit
          >
            <div className="background">
              <ProfessionView />
            </div>
          </CSSTransition>
          <CSSTransition
            in={state.matches('activity')}
            classNames={transitionDirection}
            timeout={300}
            unmountOnExit
          >
            <div className="background">
              <ActivityView />
            </div>
          </CSSTransition>
          <CSSTransition
            in={state.matches('protection')}
            classNames={transitionDirection}
            timeout={300}
            unmountOnExit
          >
            <div className="background">
              <ProtectionView />
            </div>
          </CSSTransition>
          {state.matches({ protection: 'viewing' }) ?
            <ViewingModal centered video={currentVideo} isOpen={true} toggle={() => send('CANCEL')} />
            : null}
        </div>
      </CSSTransition>
      <CSSTransition
        in={state.matches('welcome')}
        classNames={transitionHomeDirection}
        timeout={300}
        unmountOnExit
      >
        <div className="background" style={{ padding: '0px' }}>
          <WelcomeView />
        </div>
      </CSSTransition>
      {stateMail.matches('open') &&
        <SubscribeModal centered isOpen={true} toggle={() => sendMail('CLOSE')} />}
    </AppContext.Provider>
  );
}




export default App;
