import React, { useState } from 'react'
import { Row, Col, Modal, Button, ModalBody, ModalHeader, Form, FormGroup, Label, Input } from 'reactstrap'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'

function SubscribeModal(props) {
    const url = "https://aidytech.us10.list-manage.com/subscribe/post?u=3c2607479f869e1f457bce57a&amp;id=2ac9e69f03"
    const [formData, setFormData] = useState({});

    const handleInputChange = event => {
        let { name, value } = event.target;
        if (event.target.type === 'select-one')
            value = parseInt(value, 10)
        setFormData({
            ...formData,
            [name]: value
        })
    };

    return (
        <Modal {...props} size="sm">
            <ModalHeader toggle={props.toggle}>Suscribirse</ModalHeader>
            <ModalBody>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        status !== "success" ?
                            <div>
                                <div className="text-center my-3">Mantente informado con información relevante a este tema.</div>
                                {status === "error" && <div className="text-center" style={{ color: "red" }}>Ocurrio un error</div>}
                                <Form>
                                    <Row form >
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Label>Nombre(s)</Label>
                                                <Input type="text" name="FNAME" placeholder="Nombre(s)" value={formData.FNAME || ""} onChange={handleInputChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Label>Apellido(s)</Label>
                                                <Input type="text" name="LNAME" placeholder="Apellido(s)" value={formData.LNAME || ""} onChange={handleInputChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input type="email" name="EMAIL" placeholder="Email" value={formData.EMAIL || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                    <Button disabled={!formData.FNAME || !formData.LNAME || !formData.EMAIL} block color="primary" onClick={() => subscribe(formData)}>
                                        {status === "sending" && <div>Enviando...</div>}
                                        {(!status || status === "error") && "Suscribirse"}
                                    </Button>
                                </Form>
                            </div> :
                            <div className="text-center m-3">
                                <div><CheckCircleIcon size={40} style={{ color: 'green' }} /></div>
                                <div>Subscrito!</div>
                            </div>
                    )}
                />
            </ModalBody>
        </Modal>
    )
}

export default SubscribeModal

