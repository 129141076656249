import React, { useContext } from 'react';
import { Button } from 'reactstrap'
import AppContext from '../AppContext'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

function DiagnosisView(props) {
  const { state, send } = useContext(AppContext);
  return (
    <div>
      <p className="text-center" style={{ fontSize: 12 }}>Seleccione un Tipo de Paciente</p>
      <div style={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
        <Button color="secondary" block className="mt-2"
          style={{
            height: '70px', lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
          }}
          onClick={() => send({ type: 'DIAGNOSIS_SELECTED', data: 0 })}>
          <div className="text-left" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
            Paciente en hospitalización y consulta externa
              </div>
          <ChevronRightIcon style={{ flexShrink: 0, color: '#17a2b8' }} className="icon" />
        </Button>
        <Button color="secondary" block className="mt-2"
          style={{
            height: '70px', lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
          }}
          onClick={() => send({ type: 'DIAGNOSIS_SELECTED', data: 1 })}>
          <div className="text-left" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
            Paciente en labor o neonato
              </div>
          <ChevronRightIcon style={{ flexShrink: 0, color: '#17a2b8' }} className="icon" />
        </Button>
        <Button color="secondary" block className="mt-2"
          style={{
            height: '70px', lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
          }}
          onClick={() => send({ type: 'DIAGNOSIS_SELECTED', data: 2 })}>
          <div className="text-left" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
            Paciente en quirófano
              </div>
          <ChevronRightIcon style={{ flexShrink: 0, color: '#17a2b8' }} className="icon" />
        </Button>
      </div >
    </div>
  )
}

export default DiagnosisView
