import React, { useContext } from 'react';
import {  Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import AppContext from '../AppContext'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'

function ProfessionView(props) {
    const { state, send } = useContext(AppContext);
    const { professions, profession_search, diagnosis } = state.context;
    return (
      <div>
        <p className="text-center" style={{ fontSize: 12 }}>Seleccione una Profesión</p>
        <div className="mt-2 mb-3 mx-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><MagnifyIcon /></InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Buscar Profesión" type="text" value={profession_search || ""} onChange={(event) => send('SEARCH', { value: event.target.value })} />
            {profession_search ?
              <InputGroupAddon addonType="append">
                <Button onClick={() => send('CLEAN')} color="default"><CloseCircleIcon style={{ color: 'gray' }} /></Button>
              </InputGroupAddon> : null
            }
          </InputGroup>
        </div>
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {professions.filter((profession) => profession.ID_GRUPO == diagnosis).filter((profession) => profession_search ? profession.NOMBRE.includes(profession_search) : true).map((profession, index) => (
            <Button color="secondary" key={index} block className="mt-2"
              style={{
                height: '70px', lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
              }}
              onClick={() => send({ type: 'PROFESSION_SELECTED', data: profession })}>
              <div className="text-left" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                {profession.NOMBRE}
              </div>
              <ChevronRightIcon style={{ flexShrink: 0, color: '#17a2b8' }} className="icon" />
            </Button>
          ))}
        </div>
      </div>
    )
  }

export default ProfessionView
